import React from 'react';
import { Container, Grid, Typography, Button, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, Box } from '@mui/material';

const Footer = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <Grid style={{ backgroundColor: '#660066', color: '#fff' }} className={ isMobile ? 'p-2':'p-4'} container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={6} sm={6} md={4}>
        <Typography variant={ isMobile ? "body1": "h5"} gutterBottom>
          Connect with Us
        </Typography>
        <Box display="flex" className="flex-column text-white">
          <a className="text-white" href="https://twitter.com/africanbiobank" target='_blank'>Twitter</a>
          <a className="text-white" href="https://www.linkedin.com/company/african-biobank-for-cancer-research-abcr/" target='_blank'>LinkedIn</a>
        </Box>
      </Grid>
      <Grid item xs={6} sm={12} md={4} className={isMobile ? 'px-1': 'px-5'}>
        <Typography variant="body1" align="center">
          &copy; {new Date().getFullYear()} African Biobank for Cancer Research. All rights reserved.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        {/* Add contact information here */}
        <Typography variant="body1">
          Email: info@africanbiobank.org
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
