import React, {useState} from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Hidden, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../Assets/Logo-.png';
import Logo1 from '../Assets/Logo1.png';
import { Link, useLocation } from 'react-router-dom';

const NavBar = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);
  
    const handleMouseEnter = (index) => {
      setHoveredIndex(index);
    };
  
    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };

  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: '#fff', color: '#000', padding: 3 }} >
        <Toolbar style={{ paddingLeft: isMobile? '20':'150', paddingRight: 0, display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <img src={Logo} alt="Company Logo" className="py-0 m-0" style={{ height: 70, width: 'auto'}} />
          </div>
          <Hidden lgUp style={{ marginLeft: '5%'}} >
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon style={{ color: '#660066'}} />
            </IconButton>
          </Hidden>
          <Hidden lgDown style={{ width: '85%' }}>
                <List
                  component="nav"
                  aria-label="main mailbox folders"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexWrap: 'nowrap',
                    paddingRight: '2%',
                  }}
                >
                  {[
                    { label: 'Home', link: '/', },
                    { label: 'About Us', link: '/about' },
                    { label: 'Support', link: '/support' },
                    { label: 'News', link: '/news' },
                    { label: 'Contact Us', link: '/contact' }
                  ].map((item, index) => (
                    <a
                      key={index}
                      className="text-center list-item text-decoration-none"
                      style={{
                        minWidth: '70px',
                        padding: '0.15em 0.15em 0.15em',
                        cursor: 'pointer',
                        color: hoveredIndex === index ? 'purple' : 'inherit'
                      }}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      to={item.link}
                    >
                      <Link className="nav-link" to={item.link}>{item.label}</Link>
                    </a>
                    
                  ))}
                </List>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Hidden lgUp style={{ marginRight: 10}}>
        <Drawer open={openDrawer} onClose={handleDrawerToggle}>
          <List>
            <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/about" onClick={handleDrawerToggle}>
              <ListItemText primary="About" />
            </ListItem>
            <ListItem button component={Link} to="/support" onClick={handleDrawerToggle}>
              <ListItemText primary="Support the Cause" />
            </ListItem>
            <ListItem button component={Link} to="/news" onClick={handleDrawerToggle}>
              <ListItemText primary="News" />
            </ListItem>
            <ListItem button component={Link} to="/contact" onClick={handleDrawerToggle}>
              <ListItemText primary="Contact" />
            </ListItem>
          </List>
        </Drawer>
      </Hidden>
    </div>
  );
};

export default NavBar;
