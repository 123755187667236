import React from 'react';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useMediaQuery } from '@mui/material';

const ContactUs = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const containerStyle = {
    display: 'flex',
    height: '90vh',
    marginBottom: 25,
    padding: 0,
    fontFamily: 'Arial, sans-serif',
    backgroundImage: 'url("https://i.ibb.co/zhXkYXf/Light-Purple-Plain-Background.jpg")',
    backgroundSize: 'contain',
  };

  const leftSectionStyle = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    width: '100%',
    height: '100%',
  };

  const glowingSectionStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    animation: 'glowDim 4s ease-in-out infinite',
  };

  const logoStyle = {
    maxWidth: '80%',
    height: '100%',
  };

  const rightSectionStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  };

  const formStyle = {
    width: '100%',
    maxWidth: '400px',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
  };

  const socialIconsStyle = {
    marginTop: '20px',
  };

  const socialIconStyle = {
    marginRight: '10px',
    color: '#333',
  };

  const glowDimAnimation = `
    @keyframes glowDim {
      0%, 100% {
        opacity: 0.9;
        transform: scale(1);
      }
      50% {
        opacity: 0.4;
        transform: scale(1.05);
      }
    }
  `;

  return (
    <div style={containerStyle}>
      <div style={leftSectionStyle} className={isMobile ? 'd-none' : ''}>
        <div className='d-flex flex-column h-100 mt-0'>
          <div style={glowingSectionStyle} className='h-75'>
            <img src="https://i.ibb.co/1ZLVD4D/Primary-Logo-without-the-Background-2.png" alt="Logo" style={logoStyle} />
          </div>
          <div className='h-25'>
            <img src="https://i.ibb.co/RH4pxsg/Primary-Logo-without-the-Background-1.png" alt="Logo" className='h-100' style={logoStyle} />
          </div>
        </div>
      </div>
      <div style={rightSectionStyle}>
        <Form style={formStyle} className={`d-flex flex-column justify-content-around ${isMobile ? 'pt-4':''}}` } action="https://formspree.io/f/xleqlvyv" method="POST">
          <h2 className='fw-bold text-center' style={{color: '#660066'}} >GET IN TOUCH</h2>
          <input
            type="text"
            placeholder="Name"
            required
            className={`form-control ${isMobile ? 'mb-1' : 'mb-4'}`}
          />
          <input
            type="text"
            placeholder="Surname"
            required
            className={`form-control ${isMobile ? 'mb-1' : 'mb-4'}`}
          />
          <input
            type="text"
            placeholder="Subject"
            required
            className={`form-control ${isMobile ? 'mb-1' : 'mb-4'}`}
          />
          <textarea
            placeholder="Message"
            rows="5"
            required
            className={`form-control ${isMobile ? 'mb-2' : 'mb-4'}`}
          ></textarea>
          <Button type="submit" className='w-50 mx-auto' style={{backgroundColor: '#660066'}}>Send</Button>
        </Form>
        <div style={socialIconsStyle}>
          <a href="https://www.linkedin.com/company/african-biobank-for-cancer-research-abcr/" target='_blank' style={socialIconStyle}><FaLinkedin /></a>
          <a href="https://twitter.com/africanbiobank" target='_blank' style={socialIconStyle}><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmpkEzvL5O9fuGooIU8D1dU395fK5gejNiKlfsnnxfhQ&s' height={14} width={14}></img></a>
          <a href="mailto:info@example.com">info@africanbiobank.org</a>
        </div>
      </div>
      <style>{glowDimAnimation}</style>
    </div>
  );
};

export default ContactUs;