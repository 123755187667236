import React from 'react'
import { Container, Grid, Typography, Button, Card, CardContent, useMediaQuery } from '@mui/material';

function Support() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const objectives = [
    {
      id: 1,
      color: '#660066',
      title: 'Healthcare Professionals',
      description: `Engage with ABCR by sharing your expertise, participating in research collaborations, and advocating for evidence-based cancer care`
    },
    {
      id: 2,
      color: '#660066',
      title: 'Researchers',
      description: 'Join our network, contribute your knowledge, and collaborate on groundbreaking studies .Your insights can drive innovation'
    },
    {
      id: 3,
      color: '#800080',
      title: 'Philanthropists',
      description: 'Support ABCR financially or by raising awareness .Your contributions directly impact cancer research'
    },
    {
      id: 3,
      color: '#800080',
      title: 'Government Agencies',
      description: 'Partner with ABCR to align policies, allocate resources, and promote cancer research at a national level'
    },
  ]

  return (
    <div style={{ backgroundImage: `url('https://i.ibb.co/zhXkYXf/Light-Purple-Plain-Background.jpg')`, backgroundSize: 'contain', backgroundPosition: 'center'}}>
      <Container className='mx-md-auto px-md-5 py-md-2 justify-center align-center' >
        <Grid container spacing={12} className='py-2 text-center' alignItems="center">
          <Grid item xs={12} md={12} lg={12} className='mb-0 d-flex flex-column justify-content-center'>
            <Typography variant="h1" className={`font-italic pt-1 fw-bold`} gutterBottom style={{color: '#000', fontSize: isMobile? '2rem':'3rem'}}>
              SUPPORT THE CAUSE 
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={12} className={`mb-1 pt-0`}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4" className='text-center' gutterBottom style={{ color: '#660066', fontSize: isMobile? '1.5rem':'' }}>
                BECOME AN ADVOCATE
              </Typography>
            </Grid>
            {objectives.map(objective => (
              <Grid className='mx-auto mb-4'item xs={12} sm={10} md={10} lg={10} key={objective.id}>
                <Card style={{ backgroundColor: objective.color, minHeight: '120px', borderRadius: '36px' }}>
                  <CardContent className={`d-flex ${isMobile? 'p-1':''}`}>
                    <div className='mx-4'>
                      <Typography className='text-start' variant={isMobile ? 'h6': "h5"} style={{ color: '#fff' }}>{objective.title}</Typography>
                      {objective.description.split('. ').map((sentence, index) => (
                        <Typography className='text-start' key={index} variant={isMobile ? 'body2': "h6"} style={{ color: '#fff' }}>{sentence}.<br /></Typography>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12} md={9} lg={9} className={`mb-5 pt-2 ${isMobile? 'mt-2':'mt-5'} mx-auto`}>
                <Typography className="text-center" variant={isMobile ? 'h5': "h4"} gutterBottom style={{ color: '#660066' }}>DONATE</Typography>
                <Card style={{ minHeight: isMobile ? '': '200px', backgroundColor: 'rgba(255, 255, 255, 0.35)', border: '1px', borderRadius: '36px'  }}>
                  <CardContent className='d-flex flex-column justify-center'>
                    <Typography style={{
                      textAlign: 'justify',
                      textJustify: 'inter-word',
                      textAlignLast: 'justify',
                      display: 'inline-block',
                      width: '100%',
                      color: '#660066'
                    }} className={ isMobile ? "px-1": "px-5"} variant={isMobile ? 'body2': "h6"} component='div'>
                      Every contribution matters. By donating to ABCR, you directly support cancer research programs and infrastructure development.
                    </Typography>
<Button 
  variant="contained" 
  className={`${isMobile ? 'w-50' : 'w-25 p-2'} text-center mx-auto btn-lg mt-4`} 
  style={{backgroundColor: '#660066'}}
>
  <a href="Kalkidan-edit-here" style={{ textDecoration: 'none', color: 'inherit' }}>Donate Now</a>
</Button>
                  </CardContent>
                </Card>
          </Grid>

          <Grid item xs={12} md={9} lg={9} className={`mb-5 pt-2 ${isMobile ? '' : 'mt-5'} mx-auto`}>
            <Typography className="text-center" variant={isMobile ? 'h5': "h4"} gutterBottom style={{ color: '#660066' }}>JOIN OUR NEWSLETTER</Typography>
            <div className="d-flex justify-content-center align-items-center mt-4">
                  <input type="email" placeholder="Enter your email" className="mr-3 p-2" style={{ borderRadius: '8px', border: '1px solid #660066', minWidth: isMobile? '':'300px' }} />
                  <Button variant="contained" className={`text-center ${isMobile ? '':'btn-lg' } p-2 ml-2`} style={{backgroundColor: '#660066', color: '#fff', fontSize: isMobile? '12px': ''}}>
                    Join Newsletter
                  </Button>
            </div>
          </Grid>

          
        </Grid>
      </Container>
    </div>
  )
}

export default Support