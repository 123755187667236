import React from 'react';
import { Container, Grid, Typography, Button, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';

const About = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <div style={{ backgroundImage: `url('https://i.ibb.co/zhXkYXf/Light-Purple-Plain-Background.jpg')`, backgroundSize: '100% 100%', backgroundPosition: 'center' }}>
      <Container className='mx-md-auto px-md-5 py-md-2 justify-center align-center'>
        <Grid container spacing={12} className='py-2' alignItems="center">
          <Grid item xs={12} md={10} lg={10} className=' d-flex flex-column justify-content-center text-center mx-auto'>
            <Typography variant="h1" className={`mx-auto text-center font-italic pt-4 fw-bold`} gutterBottom style={{ color: '#660066', fontSize: isMobile ? '2rem': '3rem' }}>
              About Us
            </Typography>
          </Grid>

          <Grid item xs={12} md={10} lg={10} className={`d-flex flex-column justify-content-center text-center mx-auto mb-5 mt-0 pt-0`}>
            <Card style={{ minHeight: '300px', backgroundColor: 'rgba(255, 255, 255, 0.1)', border: '1px', borderRadius: '36px', width: isMobile? '100%':'95%', backgroundImage: `url('')`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', position: 'relative' }}>
              <CardContent className='d-flex flex-column justify-center'
                style={{
                  backdropFilter: 'blur(0.15px)',
                  backgroundColor: 'rgba(255, 255, 255, 0.4)',
                  borderRadius: '36px',
                }}>
                <Typography variant={isMobile ? 'body2' : 'h6'} style={{
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                  textAlignLast: 'start',
                  display: 'inline-block',
                  width: '100%',
                  color: '#660066'
                }} className={`mt-1 ${isMobile ? 'p-1' :'p-4'}`}>
                  African Biobank for Cancer Research (ABCR) is a pioneering pan-African initiative aimed at revolutionizing cancer research and treatment in Africa. We envision a transformative initiative focused on establishing a central biospecimen collection center in Addis Ababa, Ethiopia. Recognizing the urgent need for comprehensive and region-specific data, ABCR seeks to enhance oncological understanding and provide tailored treatment solutions for cancer patients across the continent.
                </Typography>
                <Typography variant={isMobile ? 'body2' : 'h6'} style={{
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                  textAlignLast: 'start',
                  display: 'inline-block',
                  width: '100%',
                  color: '#660066'
                }} className={`mt-1 ${isMobile ? 'p-1' :'p-4'}`}>
                  Our objectives include the establishment of a central biospecimen collection center, implementing rigorous protocols for ethical and standardized biospecimen collection from cancer patients, advocating for biospecimen donation, and developing a cutting-edge biobank facility equipped with advanced storage and preservation technologies. Furthermore, we aim to enhance cancer research output from Africa by African scientists.
                </Typography>
                <Typography variant={isMobile ? 'body2' : 'h6'} style={{
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                  textAlignLast: 'start',
                  display: 'inline-block',
                  width: '100%',
                  color: '#660066'
                }} className={`mt-1 ${isMobile ? 'p-1' :'p-4'}`}>
                  With a commitment to quality control and collaboration, ABCR aims to pave the way for a collaborative, data-driven approach to cancer research and treatment in Africa. Join us in our mission to make a lasting impact on the fight against cancer across the African continent.
                </Typography>
                <Link to="/support"  style={{ margin: 'auto', textDecoration: 'none' }}>
                  <Button variant="contained" className='text-center mx-auto w-100 btn-lg p-2 mt-4' style={{ backgroundColor: '#660066' }}>
                    Get Involved
                  </Button>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default About;