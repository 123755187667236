import React from 'react';
import { Container, Grid, Typography, Button, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';

const News = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <div style={{ backgroundImage: `url('https://i.ibb.co/zhXkYXf/Light-Purple-Plain-Background.jpg')`, backgroundSize: 'contain', backgroundPosition: 'center' }}>
      <Container className='mx-md-auto px-md-5 py-md-2 justify-center align-center'>
        <Grid container spacing={12} className='py-2' alignItems="center">
          <Grid item xs={12} md={10} lg={10} className=' d-flex flex-column justify-content-center text-center mx-auto'>
            <Typography variant="h1" className={`mx-auto text-center font-italic pt-4 fw-bold`} gutterBottom style={{ color: '#660066', fontSize: isMobile? '1.5rem': '3rem' }}>
            Join us in Advancing Cancer Care Across Africa
            </Typography>
          </Grid>

          <Grid item xs={12} md={10} lg={10} className={`d-flex flex-column justify-content-center text-center mx-auto mb-5 mt-0 pt-0`}>
            <Card style={{ minHeight: '300px', backgroundColor: 'rgba(255, 255, 255, 0.1)', border: '1px', borderRadius: '36px', width: isMobile? '100%':'95%'}}>
              <CardContent className='d-flex flex-column justify-center'
                style={{
                  backdropFilter: 'blur(0.15px)',
                  backgroundColor: 'rgba(255, 255, 255, 0.4)',
                  borderRadius: '36px',
                }}>
                <Typography variant={isMobile ? 'body2': "h6"} style={{
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                  textAlignLast: 'start',
                  display: 'inline-block',
                  width: '100%',
                  color: '#660066'
                }} className={`mt-1 ${isMobile ? 'p-1' :'p-4'}`}>
                  Dear Esteemed Healthcare Professionals,<br></br>
At ABCR, we are committed to improving healthcare outcomes across Africa, and we believe that addressing the challenges in cancer care is paramount to achieving this goal. We invite you to participate in a pivotal survey aimed at shaping the future of oncology in our continent.
<br className='p-1'></br> <br></br>By lending your expertise and insights to this survey, you will play a crucial role in:<ul>
  <li>Enhancing our understanding of the current landscape of cancer diagnosis, treatment, and patient care.</li>
  <li>Identifying barriers and obstacles that hinder the delivery of optimal cancer care.</li>
  <li>Prioritizing areas for improvement and innovation to ensure equitable access to high-quality cancer care.</li>
</ul>

Your participation will contribute to a comprehensive understanding of the challenges and opportunities in cancer care across Africa, ultimately leading to more effective strategies and interventions.
<br></br><br></br>We invite you to join us in this noble endeavor by taking the survey today. Your voice matters in driving positive change in cancer care across our communities.
<br></br><br></br>We thank you, <br></br>
ABCR
                </Typography>
                
                <Link to="https://docs.google.com/forms/d/1zdaq1x-Wh-rHa5Rho5xTWNQgQPP0LI9fq8PfJ42ikp8/edit"  style={{ margin: 'auto', textDecoration: 'none' }}>
                  <Button variant="contained" className='text-center mx-auto w-100 btn-lg p-2 mt-4' style={{ backgroundColor: '#660066' }}>
                    Go To Survey
                  </Button>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default News;