import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import NavBar from './Components/NavBar';
import Homepage from './Components/Homepage';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Components/Footer';
import "@fontsource/montserrat";
import { ThemeProvider, createTheme } from '@mui/material/styles'; 
import Support from './Components/Support';
import About from './Components/About';
import Contact from './Components/Contact.jsx';
import News from './Components/News.jsx';
import './Components/.well-known/pki-validation/FC9C737986E6EB9BD819901A153DF60C.txt';
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Montserrat',
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div>
          <NavBar />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/support" element={<Support />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/news" element={<News />} />
            <Route path="/.well-known/pki-validation/FC9C737986E6EB9BD819901A153DF60C.txt" element={<News />} />
          </Routes>
          <Footer />
        </div>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App;
